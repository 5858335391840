//////////////////////////////////////////////////////////////////
//	ONG Simple Lib		 										//
//	Автор RnD SoftLab (Davydov Lev aka S@DLer), 2016 - 2016.	//
//	Расширение по обработке стандартных событий.				//
//	Дата последнего изменения: 10.10.2017 23:07					//
//	Система: SimpleONG system.									//
//	GlobalSystemVersion: 0.9.0									//
//////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////
// Определяем рабочий объект библиотеки по обработке стандартных событий.
var ongEventHandler = 
{
	ongRequestControlQueue: 				[],
	ongRegistredPlugins: 					{},
	
	ong_click_callbacksList: 				{},
	ong_mouseWheel_callbacksList: 			{},
	ong_keyPress_callbacksList: 			{},
	ong_click_callbackInitFlag:				false,
	ong_mouseWheel_callbackInitFlag:		false,
	ong_keyPress_callbackInitFlag:			false,

	//////////////////////////////////
	// Функция добавления обработчика.
	// Принимает параметры:
	//		setEvent 	- тип события на которое устанавливается обработчик (обязателен).
	// 		obj 		- объект добавляемого обработчика (обязателен).
	//		objID 		- идентификатор добавляемого обработчика. Если не передан то формируется случайный идентификатор.
	//
	// Возвращает либо идентификатор добавленного обработчика, либо false, в том случае если обработчик не добавлен.
	ongCallbackSet: function( setEvent, obj, objID, useCapture )
	{
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Проверяем, что был передан тип обрабатываемого события и что на это события есть функция обработчик в текущей библиотеке.
		if (typeof(setEvent) == 'string' && 'ong_'+setEvent+'_callbacksList' in ongEventHandler)
		{
			////////////////////////////////////////////////////////////////////////////////////
			// Если в переданных параметрах обработчика есть ошибки, то завершаем работу метода.
			if (typeof(obj) == 'object' && typeof(ongEventHandler['ong_'+setEvent+'_callbackObjectCheck']) == 'function' && typeof(ongEventHandler['ong_'+setEvent+'_callbackInit']) == 'function' && ongEventHandler['ong_'+setEvent+'_callbackObjectCheck'](obj))
			{
				////////////////////////////////////////////////////////////////////////////////////////////////
				// Если не передан идентификатор добавляемого обработчика, то формируем случайный идентификатор.
				if (typeof(objID) == 'undefined' || typeof(objID) != 'string') 
				{ 
					var objID = setEvent+'-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8); return v.toString(16); });
				}

				//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Если идентификатор добавляемого обработчика уже присутствует в списке обработчиков, то завершаем работу метода.
				if (objID in ongEventHandler['ong_'+setEvent+'_callbacksList']) { console.log('event "'+setEvent+'" already exists.'); return false; }

				//////////////////////////////////////////////////////////////////////////////////////////////
				// Если глобальный обработчик событий еще не инициализирован, то производим его инициализацию.
				if (!ongEventHandler['ong_'+setEvent+'_callbackInitFlag']) { ongEventHandler['ong_'+setEvent+'_callbackInit'](useCapture || false); ongEventHandler['ong_'+setEvent+'_callbackInitFlag'] = true; }

				//////////////////////////////
				// Добавляем новый обработчик.
				ongEventHandler['ong_'+setEvent+'_callbacksList'][objID] = obj;

				/////////////////////////////////////////////////////
				// Возвращаем идентификатор добавленного обработчика.
				return objID;
			}
			else
			{
				console.log('handler event "'+setEvent+'" is incorrect.'); return false;
			}
		}
		else
		{
			console.log('set event is not recognized.'); return false;
		}
	},

	////////////////////////////////
	// Функция удаления обработчика.
	// Принимает параметры:
	//		delEvent 	- тип события с которого удаляется обработчик (обязателен).
	// 		objID 		- идентификатор удаляемого обработчика. (обязателен).	
	ongCallbackDelete: function( delEvent, objID )
	{
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Проверяем, что был передан тип обрабатываемого события и что на это события есть функция обработчик в текущей библиотеке.
		if (typeof(delEvent) == 'string' && 'ong_'+delEvent+'_callbacksList' in ongEventHandler && typeof(ongEventHandler['ong_'+delEvent+'_callbacksList']) == 'object')
		{
			if (typeof(objID) == 'string' && objID in ongEventHandler['ong_'+delEvent+'_callbacksList'])
			{
				delete ongEventHandler['ong_'+delEvent+'_callbacksList'][objID]; return true;
			}
			else
			{
				console.log('delete event "'+setEvent+'" not founded'); return false;
			}
		}
		else
		{
			console.log('delete event is not recognized.'); return false;
		}
	},

	///////////////////////////////////////////////////////////
	// Функция проверки объекта обработки события на клик мыши.
	ong_click_callbackObjectCheck: function(obj)
	{
		if (typeof(obj) == 'object' && typeof(obj.elemTarget) == 'string' && typeof(obj.clickPos) == 'string' && typeof(obj.funcCallback) == 'function') { return true; } else { return false; }
	},

	//////////////////////////////////////////////////
	// Функция инициализации обработчика на клик мыши.
	ong_click_callbackInit: function(useCapture)
	{
		//////////////////////////////////////////////////////
		// Перехватываем события клика на объектах интерфейса.
		window.addEventListener("click", function(event) 
		{
			/////////////////////////////
			// Определяем объект события.
			var e = event || window.event; 

			///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Хак для IE. Если отсутсвует объект target, но присутствует объект srcElement, то копируем объект srcElement в объект target.
			if (typeof(e.target) != 'object' && typeof(e.srcElement) == 'object') { e.target = e.srcElement; }

			//////////////////////////////////////////////////////////
			// Определяем, что у события присутствует объект 'target'.
			if (typeof(e.target) == 'object')
			{
				////////////////////////////////////////////////////////////////////////////////////////////////
				// Проходим по массиву элементов для которых определены callback-запросы на таргетинговые клики.
				if (typeof(ongEventHandler.ong_click_callbacksList) == 'object')
				{
					$.each(ongEventHandler.ong_click_callbacksList, function (elem, field) 
					{
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если в конфигурации присутствуют все необходимые параметры и сам элемент существует, то производим обработку элемента.
						if (typeof(field.elemTarget) == 'string' && $(field.elemTarget).length > 0 && typeof(field.clickPos) == 'string' && typeof(field.funcCallback) == 'function')
						{
							/////////////////////////////////////////////////////////////////////////////////////////////////
							// Если передан флаг отмены действия по умолчанию, то производим запуск функции preventDefault().
							if (field.preventDefault) { e.preventDefault(); }

							/////////////////////////////////////
							// Определяем целевой диапозон клика:
							switch(field.clickPos)
							{
								case 'outside':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if (!$(e.target).closest(field.elemTarget).length && !$(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'inside':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).closest(field.elemTarget).length || $(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'children':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).closest(field.elemTarget).length && !$(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'element':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
							}
						}
						else if (typeof(field.elemTarget) != 'string' || ($(field.elemTarget).length == 0 && (typeof(field.eventNoDeleteFlag) == 'undefined' || field.eventNoDeleteFlag == false)))
						{
							delete ongEventHandler.ong_click_callbacksList[elem];
						}
					});
				}
			}

			return true;
		}, useCapture || false);
	},

	///////////////////////////////////////////////////////////////////////////////
	// Функция проверки объекта обработки события на нажатие клавиши на клавиатуре.
	ong_keyPress_callbackObjectCheck: function(obj)
	{
		if (typeof(obj) == 'object' && typeof(obj.elemTarget) == 'string' && typeof(obj.keyPressPos) == 'string' && typeof(obj.funcCallback) == 'function') { return true; } else { return false; }
	},

	//////////////////////////////////////////////////////////////////////
	// Функция инициализации обработчика на нажатие клавиши на клавиатуре.
	ong_keyPress_callbackInit: function(useCapture)
	{
		///////////////////////////////////////////////////
		// Перехватываем события нажатия клавиш клавиатуры.
		window.addEventListener("keydown", function(event) 
		{
			/////////////////////////////
			// Определяем объект события.
			var e = event || window.event; 

			///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Хак для IE. Если отсутсвует объект target, но присутствует объект srcElement, то копируем объект srcElement в объект target.
			if (typeof(e.target) != 'object' && typeof(e.srcElement) == 'object') { e.target = e.srcElement; }

			//////////////////////////////////////////////////////////
			// Определяем, что у события присутствует объект 'target'.
			if (typeof(e.target) == 'object')
			{
				///////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Проходим по массиву элементов для которых определены callback-запросы на таргетинговые события ввода данных.
				if (typeof(ongEventHandler.ong_keyPress_callbacksList) == 'object')
				{
					$.each(ongEventHandler.ong_keyPress_callbacksList, function (elem, field) 
					{
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если в конфигурации присутствуют все необходимые параметры и сам элемент существует, то производим обработку элемента.
						if (typeof(field.elemTarget) == 'string' && $(field.elemTarget).length > 0 && typeof(field.keyPressPos) == 'string' && typeof(field.funcCallback) == 'function')
						{
							/////////////////////////////////////////////////////////////////////////////////////////////////
							// Если передан флаг отмены действия по умолчанию, то производим запуск функции preventDefault().
							if (field.preventDefault) { e.preventDefault(); }
							
							/////////////////////////////////////
							// Определяем целевой диапозон ввода:
							switch(field.keyPressPos)
							{
								case 'outside':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if (!$(e.target).closest(field.elemTarget).length && !$(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'inside':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).closest(field.elemTarget).length || $(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'children':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).closest(field.elemTarget).length && !$(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
								case 'element':

									////////////////////////////////////////////////////////
									// Проверяем, что клик был сделан за пределами элемента.
									if ($(e.target).is(field.elemTarget)) 
									{
										///////////////////////////////////////////////////////////////////////////////////////////////////////////
										// В текущем режиме запускаем callback-функцию только в случае если элемент находится в режиме отображения.
								        if ($(field.elemTarget).is(":visible")) 
								        {
											///////////////////////////////////////////////////////////////////////////////////////////////////////////
											// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
											if (typeof(field.funcParams) == 'object')
											{
												var args = [];
												args.push(e);
												$.each(field.funcParams, function(paramIndex, paramValue) { args.push(paramValue); });
												field.funcCallback.apply(this, args);
											}
											else
											{
												field.funcCallback(e);
											}
								        }
								    }

								break;
							}
						}
						else if (typeof(field.elemTarget) != 'string' || ($(field.elemTarget).length == 0 && (typeof(field.eventNoDeleteFlag) == 'undefined' || field.eventNoDeleteFlag == false)))
						{
							delete ongEventHandler.ong_keyPress_callbacksList[elem];
						}
					});
				}
			}

			return true;
		}, useCapture || false);
	},

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Функция обработчик запросов на формирование истории запросов через функцию history.pushState.
	// Производит формирование строки запроса, для добавления в историю, исходя из параметров Ajax-запроса.
	// Принимает Следующие параметры:
	//	request_confs	- Объект с данными для передачи (необязательный). В нем может быть передан список действий при клике на кнопку назад. Подробности в документации.
	//	confs			- Объект с конфигурационными данными (необязательный).
	//		executeState		- вызываемая функция: (pushState, replaceState).
	//		forecePush			- флаг добавления запроса в историю в любом случае (для инициализации страниц).
	//		pageTitle			- заголовок страницы, если не передан то используется текущее значение.
	//		pageLink 			- заменяемая ссылка адресной строки. При ее наличии используется как есть, без дополнительных подстановок.
	//		pageProto 			- используемый протокол передачи данных.
	//		pageHost 			- используемое имя хоста.
	//		pageLinkPaths 		- передаваемые параметры файловой структуры адреса. Передаются в виде строки параметров разделенных символом "/" или в виде списка.
	//		pageLinkParams 		- передаваемые параметры адресной строки. Передаются в виде строки параметров разделенных символом "&" или в виде списка.
	//		pageLinkParamsType 	- тип замены параметров адресной строки.
	//								ins - заменяет все параметры на переданные (используется по умолчанию).
	//								apr	- добавляет переданные параметры к текущим с заменой совпадающих параметров.
	//								apw	- добавляет переданные параметры к текущим с оставлением текущих совпадающих параметров.
	//	executeFlag 	- Флаг определяющий необходимость немедленного выполнения указанных на выполнение действий.
	ongDefaultHistoryPushState: 	function( request_confs, confs, executeFlag )
	{
		///////////////////////////////////////////////////////////////////////////////////////////////////////
		// Если не передан объект данных для передачи или он не является объектом, то определяем пустой объект.
		if (typeof(request_confs) != 'object') { request_confs = {}; } 

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Если не передан объект конфигурационных данных для передачи или он не является объектом, то определяем пустой объект.
		if (typeof(confs) != 'object') { confs = {}; }

		////////////////////////////////
		// Определяем рабочие параметры.
		var currentLink = '',				// Текущая адресная строка.
			changeLink = '', 				// Заменяемая адресная строка.
			changeTitle = '',				// Заменяемый заголовок страницы.
			forecePush = false,				// Добавление запроса в историю в любом случае (для инициализации страниц).
			executeState = 'pushState',		// Вызываемая функция по умолчанию.
			executeStatesList = [ 'pushState', 'replaceState' ],
			sysParams = [ 'ongac', 'page', 'ong_mod', 'ong_mod_widg', 'ong_mod_type', 'isAjaxRequest' ];

		//////////////////////////////////
		// Определяем исполняемую функцию:
		if (typeof(confs.executeState) != 'undefined' && executeStatesList.indexOf(confs.executeState) !== -1) { executeState = confs.executeState; }

		///////////////////////////////////////////////////////////////////////////////////
		// Если не передано значение заголовка страницы, то сохраняем его текущее значение.
		if (typeof(confs.pageTitle) == 'undefined' || confs.pageTitle == '') { changeTitle = document.title; } else { changeTitle = confs.pageTitle; }

		/////////////////////////////////////////////////////////////
		// Если передан флаг проталкивания, то определяем его в true.
		if (typeof(confs.forecePush) !== 'undefined' && confs.forecePush === true) { forecePush = true; }

		//////////////////////////////////////////////
		// Формируем значение текущей адресной строки.
		currentHref = window.location.protocol + '//'+ window.location.hostname + window.location.pathname + window.location.search;

		////////////////////////////////////////////////////////////////////////////////////
		// Если не передана прямая ссылка на переопределение, то производим ее формирование.
		if (typeof(confs.pageLink) == 'string' && confs.pageLink != '')
		{
			changeLink = confs.pageLink;
		}
		else
		{
			///////////////////////////////////////////////////////////////////////////
			// Если передан используемый протокол, то добавляем его к заменяемому пути.
			if (typeof(confs.pageProto) == 'string' && (confs.pageProto == 'http' || confs.pageProto == 'https'))
			{
				changeLink = confs.pageProto + '//';
			}
			else
			{
				changeLink = window.location.protocol + '//';
			}

			///////////////////////////////////////////////////////////////////////
			// Если передан используемый хост, то добавляем его к заменяемому пути.
			if (typeof(confs.pageHost) == 'string' && confs.pageHost != '')
			{
				changeLink += confs.pageHost;
			}
			else
			{
				changeLink += window.location.hostname;
			}

			/////////////////////////////////////////////////////////////////////////////////////////
			// Если переданы параметры файловой структуры адреса, то добавляем их к заменяемому пути.
			if (typeof(confs.pageLinkPaths) == 'object')
			{
				changeLink += '/' + $.map(confs.pageLinkPaths, function(e){ return e; }).join('/');
			}
			else if(typeof(confs.pageLinkPaths) == 'string' && confs.pageLinkPaths != '')
			{
				changeLink += confs.pageLinkPaths.charAt(0) == '/' ? confs.pageLinkPaths : '/'+ confs.pageLinkPaths;
			}
			else
			{
				changeLink += window.location.pathname;
			}

			///////////////////////////////////////////////////////////////////////////////
			// Если переданы параметры адресной строки, то добавляем их к заменяемому пути.
			if (typeof(confs.pageLinkParams) == 'object' || (typeof(confs.pageLinkParams) == 'string' && confs.pageLinkParams != ''))
			{
				var changeLinkParams = {},	// Заменяемые параметры адресной строки.
					changeLinkParamsString = ''; // Строка параметров адресной строки.

				////////////////////////////////////////////////
				// Определяем список текущих параметров запроса.
				// Если передан способ замены параметров и он не равен значению 'ins', то добавляем текущие параметры в итоговый запрос.
				if (window.location.search != '' && typeof(confs.pageLinkParamsType) == 'string' && confs.pageLinkParamsType != 'ins')
				{
					var currLinkParamsList = window.location.search.charAt(0) == '?' ? window.location.search.substr(1).split('&') : window.location.search.split('&');
					for (var i = 0; i < currLinkParamsList.length; i++)
					{
						var currLinkParam = currLinkParamsList[i].split('=');
						if (typeof(currLinkParam[0]) == 'string' && currLinkParam[0] != '')
						{
							changeLinkParams[currLinkParam[0]] = typeof(currLinkParam[1]) != 'undefined' ? currLinkParam[1] : '';
						}
					}
				}

				///////////////////////////////////////////////
				// Формируем текущие параметры адресной строки.
				if (typeof(confs.pageLinkParams) == 'object')
				{
					$.each(confs.pageLinkParams, function(paramName, paramValue) 
					{
						/////////////////////////////////////////////////////////////////////
						// Отбрасываем системные параметры, неотображаемые в адресной строке.
						if ($.inArray(paramName, sysParams) == -1) 
						{
							if (typeof(paramName) == 'string' && paramName != '')
							{
								///////////////////////////////////////////////////////////////////////////////////////////////////////////////
								// В зависимости от указанного способа замены параметров адресной строки, производим соответствующую обработку.
								if (typeof(confs.pageLinkParamsType) == 'string' && confs.pageLinkParamsType == 'apw')
								{
									if (typeof(changeLinkParams[encodeURIComponent(paramName)]) == 'undefined')
									{
										changeLinkParams[encodeURIComponent(paramName)] = encodeURIComponent(paramValue);
									}
								}
								else
								{
									changeLinkParams[encodeURIComponent(paramName)] = encodeURIComponent(paramValue);	
								}
							}
						}
					});
				}
				else
				{
					var changeLinkParamsList = confs.pageLinkParams.charAt(0) == '?' ? confs.pageLinkParams.substr(1).split('&') : confs.pageLinkParams.split('&');
					for (var i = 0; i < changeLinkParamsList.length; i++)
					{
						var changeLinkParam = changeLinkParamsList[i].split('=');
						if (typeof(changeLinkParam[0]) == 'string' && changeLinkParam[0] != '')
						{
							///////////////////////////////////////////////////////////////////////////////////////////////////////////////
							// В зависимости от указанного способа замены параметров адресной строки, производим соответствующую обработку.
							if (typeof(confs.pageLinkParamsType) == 'string' && confs.pageLinkParamsType == 'apw')
							{
								if (typeof(changeLinkParams[encodeURIComponent(changeLinkParam[0])]) == 'undefined')
								{
									changeLinkParams[encodeURIComponent(changeLinkParam[0])] = typeof(changeLinkParam[1]) != 'undefined' ? encodeURIComponent(changeLinkParam[1]) : '';
								}
							}
							else
							{
								changeLinkParams[encodeURIComponent(changeLinkParam[0])] = typeof(changeLinkParam[1]) != 'undefined' ? encodeURIComponent(changeLinkParam[1]) : '';
							}
						}
					}
				}

				////////////////////////////////////////////////////////
				// Формируем строку заменямых параметров адреснойстроки.
				$.each(changeLinkParams, function(paramName, paramValue) 
				{
					changeLinkParamsString += changeLinkParamsString != ''?'&':'';
					changeLinkParamsString += paramName+'='+paramValue;
				});

				///////////////////////////////////////////////////////////
				// Формируем итоговый результат заменяемой адресной строки.
				if (changeLinkParamsString != '') { changeLink += '?'+changeLinkParamsString; }
			}
			else
			{
				changeLink += window.location.search;
			}
		}

		/////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Если не передан объект с директивами действия, то просто производим переадресацию на полученный адрес.
		if ($.isEmptyObject(request_confs))
		{
			///////////////////////////////////////////////////////////////////
			// Добавляем ссылку в историю броузера и меняем заголовок страницы.
			window.history[executeState]({ 'pageLink': changeLink }, changeTitle, changeLink);
		}
		else
		{
			//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Если в итоге текущая полученная ссылка равна существующей ссылке в адресной строке, то пропускаем добавление ссылки в историю броузера.
			if (currentHref != changeLink || forecePush)
			{
				///////////////////////////////////////////////////////////////////
				// Добавляем ссылку в историю броузера и меняем заголовок страницы.
				window.history[executeState]({ 'request_confs': request_confs, 'pageLink': changeLink}, changeTitle, changeLink);
				document.title = changeTitle;

				/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Если передан флаг немедленного выполнения предписанных директив действия, то запускаем соответствующую функцию-обработчик.
				if (typeof(executeFlag) != 'undefined' && executeFlag === true) 
				{ 
					ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder ( 'success', 'ongDefaultRequestResultShow', { 'type': 'multiply_result_handler', 'request_confs': request_confs });
				}
			}
		}
	},

	/////////////////////////////////////////////////////////////////////
	// Определяем функцию отслеживания отображения элементов на странице.
	// При отображении отслеживаемого элемента, ему добавляется специальный класс "ong-in-view".
	ongCheckIfInView: function( event ) 
	{
		if (typeof(event.data.viewElem) === 'string' && $(event.data.viewElem).length > 0)
		{
			var window_height = $(window).height(),
				window_top_position = $(window).scrollTop(),
				window_bottom_position = (window_top_position + window_height),
				animation_elements = $(event.data.viewElem);

			$.each(animation_elements, function() 
			{
				var element = $(this),
					element_height = element.outerHeight(),
					element_top_position = element.offset().top,
					element_bottom_position = (element_top_position + element_height);

				/////////////////////////////////////////////////////////
				// Проверяем, отображется ли текущий контейнер на экране.
				if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) 
				{
					element.addClass('ong-in-view');
				} 
				else 
				{
					element.removeClass('ong-in-view');
				}
	  		});
	  	}
	}
};

//////////////////////////////////////////////////
// Определяем задачи при полной загрузке страницы.
$(document).ready(function () 
{
	//////////////////////////////////////////////////////////
	// Определяем действия по нажатию кнопки назад в броузере.
	$(window).bind('popstate', function(event) 
	{
		////////////////////////////////////////////////
		// Определяем сохраненные переменные по запросу.	
		var state = event.originalEvent.state;

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Если данные для осуществления ajax-подзапроса существуют, то производим вызов метода по обнаружению и запуску необходимых обработчиков.
		// Иначе производим переадресацию по прямой ссылке, сохраненной в истории запроса.
		if (state != null && typeof(state) == 'object' && typeof(state.request_confs) == 'object' && !$.isEmptyObject(state.request_confs)) 
		{
			ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder ( 'success', 'ongDefaultRequestResultShow', { 'type': 'multiply_result_handler', 'request_confs': state.request_confs });
			return false;
		}
		else if (state != null && typeof(state) == 'object' && typeof(state.pageLink) == 'string' && state.pageLink != '')
		{
			window.location.href = state.pageLink;
			return false;
		}
	});
});

//////////////////////////////////////////////////////////////////////////////
// Формируем глобальный кроссброузерный метод отслеживания события mouseWheel.
// Пример использования: addWheelListener( elem, function( e ) { console.log( e.deltaY ); e.preventDefault(); } );
// Автор: developer.mozilla.org (Leo240, aethawalka)
(function(window, document) 
{
    var prefix = "", _addEventListener, support;

    /////////////////////
    // detect event model
    if ( window.addEventListener ) { _addEventListener = "addEventListener"; } else { _addEventListener = "attachEvent"; prefix = "on"; }

    ///////////////////////////////
    // detect available wheel event
    support = "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
              document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
              "DOMMouseScroll"; // let's assume that remaining browsers are older Firefox

    window.addWheelListener = function( elem, callback, useCapture ) 
    {
        _addWheelListener( elem, support, callback, useCapture );

        //////////////////////////////////////////////
        // handle MozMousePixelScroll in older Firefox
        if( support == "DOMMouseScroll" ) { _addWheelListener( elem, "MozMousePixelScroll", callback, useCapture ); }
    };

    function _addWheelListener( elem, eventName, callback, useCapture ) 
    {
        elem[ _addEventListener ]( prefix + eventName, support == "wheel" ? callback : function( originalEvent ) 
        {
            !originalEvent && ( originalEvent = window.event );

            ///////////////////////////////////
            // create a normalized event object
            var event = 
            {
            	//////////////////////////////////////////
                // keep a ref to the original event object
                originalEvent: originalEvent,
                target: originalEvent.target || originalEvent.srcElement,
                type: "wheel",
                deltaMode: originalEvent.type == "MozMousePixelScroll" ? 0 : 1,
                deltaX: 0,
                deltaY: 0,
                deltaZ: 0,
                preventDefault: function() 
                {
                    originalEvent.preventDefault ? originalEvent.preventDefault() : originalEvent.returnValue = false;
                }
            };
            
            ///////////////////////////////////////////////////////
            // calculate deltaY (and deltaX) according to the event
            if ( support == "mousewheel" ) 
            {
                event.deltaY = - 1/40 * originalEvent.wheelDelta;

                ///////////////////////////////////
                // Webkit also support wheelDeltaX
                originalEvent.wheelDeltaX && ( event.deltaX = - 1/40 * originalEvent.wheelDeltaX );
            } 
            else 
            {
                event.deltaY = originalEvent.detail;
            }

            /////////////////////////////////
            // it's time to fire the callback
            return callback( event );

        }, useCapture || false );
    }

})(window,document);